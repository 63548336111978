"use strict";

let __SWNS = {};
(($, win, doc)=> {    // JQ ES6 sw Pack

  let $win = $(win),
      $doc = $(doc),
      $html = $('html'),
      $body = $('body'),
      swns = {
        theDate() {
          let today = new Date();
          let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          let y = today.getFullYear();
          let m = today.getMonth() + 1;
          let d = today.getDate();
          let w = today.getDay();
          let H = today.getHours();
          let M = today.getMinutes();
          let S = today.getSeconds();
          if (m < 10) { m = "0" + m; }
          if (d < 10) { d = "0" + d; }
          if (H < 10) { H = "0" + H; }
          if (M < 10) { M = "0" + M; }
          if (S < 10) { S = "0" + S; }
          return `${day[w]}:` + m + "/" + d + "/" + H + ":" + M + "." + S;
        },
        goToTop: function() {
          let $goToTop = $("#goto-top");
          $goToTop.hide();
          $.timeout = function(time) {
            return $.Deferred(function(dfd) {
              return setTimeout(dfd.resolve, time);
            }).promise();
          };
          $win.on("scroll", function() {
            if ($(this).scrollTop() > 555) {
              $goToTop.stop(true, true).fadeIn();
            } else {
              $goToTop.fadeOut();
            }
          });
          $goToTop.on("click", function(e) {
            e.preventDefault();
            $("body,html").animate({ scrollTop: 0 }, 200);
          });
        },
        getUrlVars() {
          let vars = [];
          let max = 0;
          let hash = '';
          let array = '';
          let url = window.location.search;
          hash = url.slice(1).split('&');
          max = hash.length;
          let i = 0;
          while (i < max) {
            array = hash[i].split('=');
            /*fixup 日本語にデコードUTF8
            *  ※ すべての文字がエンコードされるので、/tatsuya/js/index.htm などのパスを表す文字列には注意が必要です。
              ※ スラッシュ (/) もエンコードされるので、Web サーバーへの要求として送信する場合は無効になります。
              ※ 文字列に URI コンポーネントが複数含まれる場合は、encodeURI 関数を使用します。
              */
            //vars.push(array[0]);
            vars.push( decodeURIComponent(array[0]) );
            //vars[array[0]] = array[1];
            vars[array[0]] = decodeURIComponent(array[1]);
            i++;
          }
          return vars;
        }
      }; // swns{}

  /* animateCallback
  * # fixme 未使用だからはずす
  */

  /* 画像プリローダーAssy */
  let preloader = (images)=> {
    $(images).each(function() { (new Image()).src = this; });
  };
  $(()=> { preloader([
    "/images/favicon.png",
    "/images/logos_2.svg",
    ]);
  });


  /* plugin Type BlockLink */
  $.fn.blocklinkAssy = function() { // bind under this
    $(this).on("click", function(e) {
      e.preventDefault();
      win.open($(this).find("a").attr("href"), $(this).find("a").attr("target") || "_self");
    }).css({ cursor: "pointer" });
  };


  let navCurrentAdd = ()=> {
    let filename = $(".current-control").attr("id");
    //let url = win.location.href.split("/");
    //url = url[url.length -1].split(".html")[0];
    $("#SideNavBase").find("li").each(function(i, val) {
      $(this).removeClass("current");
      let nav_id = $(this).attr("id");
      if (nav_id === filename) { $(this).addClass("current"); }
    })
  };


  // do it DRF
  $(()=> {
    /*トップに戻る Assy */
    swns.goToTop();

    /*UI / UA */
    $("#page #wrapper").find("h3").each(function() {
      return $(this).wrapInner("<span class='add-span' />");
    });

    /* BlockLink Assy */
    $(".hNavi").blocklinkAssy();

    // add current class for nav
    navCurrentAdd();

    /* SideNav */
    $("#SideNavBase li").clone().appendTo("#SideNavClone ul");



    /**
     * swAnchor アンカー残し版（/#hoge） no return false;
     * @ fixup 外部リンクを避ける修正 & if(target.length) {}
     */
    $('a.swAnchor').click(function (e) {
      //e.preventDefault();
      let speed = 543;
      let href = $(this).attr("href");
      if (href.indexOf("#") === 0) {
        let target = $(href === "#" || href === "" ? 'html' : href);
        if (target.length) {
          let position = target.offset().top;
          $("html, body").animate({scrollTop: position - 30}, speed, "easeOutCirc");
          //return false;
        }
      }
    });

  }); // DRF }



  /* .es6 サイドナビを固定する場合 (load) */
  $win.on("load", function() {
    // offset()で画像を読み込む前に位置を取得(DRF)してしまうことで、位置がずれる場合があり
    let floatMenu, lastBottomAt, lastBottomHeight, offsetTop, scrollTop;
    let nav = $('.list-fixed');
    let bottomAt = $("#lastBottomAt");
    if (nav[0] != null) { offsetTop = nav.offset().top; }
    if (bottomAt[0] != null) { lastBottomAt = bottomAt.offset().top; }
    scrollTop = void 0;
    // bottomAt あったら固定解除 ケツからの場合は下記だがGナビは上からだからいらない
    //if (nav[0] != null) { lastBottomHeight = bottomAt.offset().top - nav.height()- bottomAt.height(); }
    let scrollHeight, scrollPosition;
    floatMenu = () => {
      scrollTop = $win.scrollTop();
      // https://www.softel.co.jp/blogs/tech/archives/4308
      scrollHeight = $doc.height();
      scrollPosition = $win.height() + scrollTop;
      bottomAt = lastBottomAt <= scrollPosition;

      // スクロール位置がメニューのtop座標を超えたら固定にする
      if ((scrollTop > offsetTop) && !bottomAt) {
        nav.addClass('add-fixed');
        return nav.css({
          "position": "",
          "top": ""
        });
      } else if (scrollTop < offsetTop) {
        nav.removeClass('add-fixed');
        return nav.css({
          "position": "",
          "top": ""
        });
      } else if (bottomAt) {
        // #lastBottomAt あったら固定解除する
        nav.removeClass('add-fixed');
        return nav.css({
          "position": "absolute",
          "top": -100 + "px"
          //"top": lastBottomHeight + "px"
        });
      }
    };
    if (nav[0] != null) {
      $win.on('scroll', _.throttle((function(e) { return floatMenu(); }), 11));
    }
  });


})(jQuery, window, document);

